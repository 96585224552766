<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">budget documents</div>
      </v-row>
      <v-row class="">
        <div class="">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. A earum
          dolor incidunt assumenda quia cumque optio sed minus! Ab eaque
          veritatis culpa error deleniti reiciendis debitis aliquam ex dolore
          repellendus!
        </div>
      </v-row>
    </v-col>

    <div class="pa-2 mt-5">
      <v-list class="text-capitalize" subheader>
        <v-list-item
          link
          @click="pdfDialog = true"
          v-for="item in budgetItems1"
          :key="item.title"
        >
          <v-list-item-icon>
            <v-icon color="primary"> {{ item.icon }} </v-icon>
          </v-list-item-icon>
          <v-list-item-title> {{ item.title }} </v-list-item-title>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-subheader inset>more documents</v-subheader>

        <v-dialog width="auto" v-for="item in budgetItems2" :key="item.title">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item link v-on="on" v-bind="attrs">
              <v-list-item-icon>
                <v-icon color="primary"> {{ item.icon }} </v-icon>
              </v-list-item-icon>
              <v-list-item-title> {{ item.title }} </v-list-item-title>
              <v-list-item-action>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>

          <v-card width="500" class="mx-auto">
            <v-card-title>
              <p class="text-uppercase mx-auto">{{ item.title }}</p>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-text-field
                label="Search"
                outlined
                dense
                class="mt-2"
                append-icon="mdi-magnify"
              ></v-text-field>
              <v-list>
                <v-list-item
                  v-for="child in item.children"
                  :key="child"
                  link
                  @click="childPdfDialog = true"
                >
                  <v-list-item-icon>
                    <v-icon> mdi-circle-small </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="text-capitalize">
                    {{ child }}
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-icon> mdi-file-pdf-box </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-dialog v-model="childPdfDialog">
                <pdf-viewer />
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-divider inset></v-divider>

        <v-subheader inset>more documents</v-subheader>

        <v-dialog width="auto" v-for="item in budgetItems3" :key="item.title">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item link v-on="on" v-bind="attrs">
              <v-list-item-icon>
                <v-icon color="primary"> {{ item.icon }} </v-icon>
              </v-list-item-icon>
              <v-list-item-title> {{ item.title }} </v-list-item-title>
              <v-list-item-action>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>

          <v-card width="500" class="mx-auto">
            <v-card-title>
              <p class="text-uppercase mx-auto">
                {{ item.title }}
              </p>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-text-field
                label="Search"
                outlined
                dense
                class="mt-2"
              ></v-text-field>
              <v-list>
                <v-list-item
                  v-for="child in item.children"
                  :key="child"
                  link
                  @click="childPdfDialog2 = true"
                >
                  <v-list-item-icon>
                    <v-icon> mdi-circle-small </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="text-capitalize">
                    {{ child }}
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-icon> mdi-chevron-right </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-dialog v-model="childPdfDialog2" width="auto">
                <v-card width="500">
                  <!-- <v-card-title>
                    <p class="text-uppercase mx-auto">
                      {{ child }}
                    </p>
                  </v-card-title> -->
                  <v-card-text>
                    <v-text-field
                      label="Search"
                      class="pt-2"
                      outlined
                      append-icon="mdi-magnify"
                      dense
                    ></v-text-field>
                    <v-list-item link @click="pdfDialog = true">
                      <v-list-item-title class="text-capitalize">
                        more documents
                      </v-list-item-title>
                      <v-list-item-action>
                        <v-icon> mdi-file-pdf-box </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-list>
    </div>

    <v-dialog v-model="pdfDialog">
      <pdf-viewer />
    </v-dialog>
  </div>
</template>

<script>
import PdfViewer from "../PdfViewer.vue";
export default {
  components: { PdfViewer },
  data() {
    return {
      financialYearItems: [],
      pdfDialog: false,
      childPdfDialog: false,
      childPdfDialog2: false,
      budgetItems1: [
        {
          title: "key to budget document",
          icon: "mdi-script-text-key",
        },
        {
          title: "budget highlights",
          icon: "mdi-book-search",
        },
        {
          title: "budget speech",
          icon: "mdi-book-open",
        },
        {
          title: "finance bill",
          icon: "mdi-file-chart",
        },
        {
          title: "memorandum explaining the provisions in the financial bill",
          icon: "mdi-chart-tree",
        },
        {
          title: "implementation of budget announcements",
          icon: "mdi-finance",
        },
      ],
      budgetItems2: [
        {
          title: "budget at a glance",
          icon: "mdi-book",
          children: [
            "budget at a glance",
            "deficit statistics",
            "budget profile",
            "receipts",
            "expenditure",
            "outlay on major schemes",
          ],
        },
        {
          title: "annual financial statement",
          icon: "mdi-script-text",
          children: [
            "consolidated funds",
            "disbursements charged on consolidated funds",
            "contigency fund",
            "public account",
          ],
        },
        {
          title: "receipt budget",
          icon: "mdi-receipt",
          children: [
            "introductory note",
            "abstract of receipts",
            "summary of estimates",
            "annexures",
            "assets and liabiluty statements",
          ],
        },
      ],
      budgetItems3: [
        {
          title: "expenditure profile",
          icon: "mdi-finance",
          children: [
            "part i - general",
            "part ii - states",
            "part iii - external sector",
            "part iv - establishment and public enterprises",
            "part v - railway statements",
          ],
        },
        {
          title: "expenditure budget",
          icon: "mdi-finance",
          children: [
            "ministry of water",
            "ministry of finance",
            "ministry of coal",
            "ministry of agriculture",
            "ministry of atomic energy",
          ],
        },
        {
          title: "demands for grants",
          icon: "mdi-finance",
          children: [
            "ministry of water",
            "ministry of finance",
            "ministry of coal",
            "ministry of agriculture",
            "ministry of atomic energy",
          ],
        },
        {
          title: "custom notifications",
          icon: "mdi-finance",
          children: ["custom notifications", "explanatory notes"],
        },
      ],
    };
  },
};
</script>

<style></style>
